<template>
  <div class="pt-2 pr-4 pb-2 pl-4 purchase-ht1">
    <form
      class="row purchase-ht4"
      enctype="multipart/form-data"
      @submit.prevent="submit"
    >
      <div class="col-12 pb-1">
        <div class="row">
          <div class="col-10 pl-0">
            <h4 class="content-title content-title-xs">Purchase Order Edit</h4>
          </div>
          <div class="col-2 float-right text-right pr-0 pl-5">
            <select
              v-model="formData.branch"
              class="form-control right-select-drop"
              :disabled="branchDisabled"
              @change="getDataByBranch"
            >
              <option value>Select Branch</option>
              <option
                v-for="(branch, index) in dataSets.branches"
                :key="index"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-3 bg-theam-secondary mx-0 p-2">
        <div
          :class="
            dataSets.purchase_setting.shipping_details == 1
              ? 'purchase-supplier-ht'
              : 'purchase-supplier-ht-change'
          "
        >
          <div>
            <span class="title-supplier">Supplier</span>
            <!-- <button
                class="float-right btn btn-primary btn-xs"
                type="button"
                @click="
                $store.dispatch('modalWithMode', {
                  id: 'newSupplier',
                  mode: 'create',
                })
              "
            >
              New Supplier
            </button>-->
          </div>
          <div class="input-group py-2">
            <button
              id="supplier_name"
              class="btn-primary input-group-text"
              type="button"
            >
              <i class="fa fa-user"></i>
            </button>
            <input
              type="text"
              class="form-control"
              placeholder="Supplier Name"
              v-model="formData.supplierName"
              disabled
            />
          </div>
          <div class="row">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text" for="date">Order Date *</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                v-model.trim="formData.date"
                class="form-control"
                required
                type="date"
              />
            </div>
          </div>
          <div class="row py-2">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text" for="order_id">Order ID</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                v-model="orderIdWithPrefix"
                class="form-control"
                type="text"
                disabled
              />
            </div>
          </div>
        </div>
        <div class>
          <div class="row" v-if="dataSets.purchase_setting">
            <div class="col-7 mr-0 pr-0">
              <button
                class="btn btn-xs btn-primary"
                type="button"
                @click="$store.dispatch('modalOpen', 'shippingDetils')"
                v-if="dataSets.purchase_setting.shipping_details == 1"
              >
                Shipping Detail
              </button>
            </div>
            <div class="col-5 ml-0 pt-0 d-flex justify-content-end">
              <div
                class="from-group-check"
                v-if="dataSets.purchase_setting.export == 1"
              >
                <input
                  id="expense_import"
                  v-model="formData.shipping_type"
                  class="group-check-box purchase-import-check"
                  name="expense_type"
                  type="checkbox"
                  value="0"
                />
                <label
                  class="page-text-import pd-t-10 ml-2 mb-0"
                  for="expense_import"
                  >Import</label
                >
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text" for="payment_method"
                >Payment Method</label
              >
            </div>
            <div class="col-7 ml-0 pl-0">
              <select
                class="form-control"
                v-model="formData.payment_method"
                @change="selectPaymentMethod"
              >
                <option value selected disabled>Select Method</option>
                <option
                  v-for="(methods, index) in dataSets.paymentMethods"
                  :key="index"
                  :value="methods.id"
                >
                  {{ methods.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text">Bank</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <select
                v-model="formData.bank"
                v-if="formData.paymentTitle.toLowerCase() == 'bank'"
                class="form-control"
                @change="selectBank()"
              >
                <option value v-if="dataSets.banks.length == 0">
                  No Banks found
                </option>
                <option
                  v-else
                  v-for="(bank, index) in dataSets.banks"
                  :key="index"
                  :value="bank.id"
                >
                  {{ bank.bank }}
                </option>
              </select>
              <input class="form-control" type="text" v-else disabled />
            </div>
          </div>
          <div class="row">
            <!-- Ref. is replaced with txn. id -->
            <div class="col-5 mr-0 pr-0">
              <label class="page-text" for="txn_id">Ref.</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                id="txn_id"
                v-model.trim="formData.txn_id"
                class="form-control"
                placeholder="Payment Ref."
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 mx-0 pl-2 pr-0 purchase-ht2">
        <div class="bg-theam-secondary p-2">
          <div class="row pt-0">
            <div class="col-4 mr-0 pr-0">
              <div class="page-text">Item</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    id="product"
                    class="btn-primary input-group-text"
                    type="button"
                  >
                    <i class="fas fa-briefcase"></i>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Item"
                  v-model="items.name"
                  data-toggle="modal"
                  data-target="#purchaseItemList"
                  @click="openPurchaseitemComponent()"
                  readonly
                />
              </div>
            </div>
            <div class="col-1 pl-2 order-add-item-btn-pd">
              <button
                class="float-left btn btn-primary btn-xs px-0"
                type="button"
                @click="
                  $store.dispatch('modalWithMode', {
                    id: 'newItem',
                    mode: 'create',
                  })
                "
              >
                New Item
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-2 mr-0 pr-0 pt-2 zi-0">
              <div class="page-text">Quantity *</div>
              <div class="input-group">
                <input
                  v-model.number="items.quantity"
                  class="form-control"
                  min="1"
                  placeholder="0"
                  type="number"
                  @keyup="calculatePurchaseDiscount()"
                  @change="calculatePurchaseDiscount()"
                />
                <div class="input-group-prepend">
                  <span
                    id="quantity"
                    class="input-group-text"
                    style="background: #8db8ee; color: black"
                    >{{ items.unit_type }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-2 mr-0 pr-0 pt-2">
              <div class="page-text">Order Rate</div>
              <div class="input-group">
                <input
                  v-model="items.order_rate"
                  class="form-control"
                  placeholder="Amount"
                  step="any"
                  min="0"
                  type="number"
                  @keyup="calculatePurchaseDiscount()"
                  @change="calculatePurchaseDiscount()"
                />
              </div>
            </div>
            <div class="col-2 pt-2 pr-0 pl-1">
              <div class="page-text">Order Discount</div>
              <div class="input-group">
                <input
                  class="form-control"
                  v-model="items.order_discount_percentage"
                  placeholder="0.00"
                  step="any"
                  min="0"
                  type="number"
                  @keyup="calculatePurchaseDiscount()"
                  @change="calculatePurchaseDiscount()"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text px-1" style="min-width: 0px"
                    >%</span
                  >
                </div>
                <input
                  class="form-control wd-20pimp"
                  v-model="items.order_discount_amount"
                  placeholder="0.00"
                  step="any"
                  type="number"
                  min="0"
                  @keyup="changePurchaseRate()"
                  @change="changePurchaseRate()"
                  @keydown.tab.exact="
                    options.edit == true ? updateItem() : addItem()
                  "
                />
              </div>
            </div>
            <div class="col-2 mr-0 pr-0 pt-2">
              <div class="page-text">Total</div>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  v-model="items.total"
                  disabled
                />
              </div>
            </div>
            <div class="col-4 d-flex justify-content-end">
              <div v-if="options.edit">
                <div
                  class="btn btn-success btn-xs mt-4"
                  @click="updateItem"
                  type="button"
                >
                  Update Item
                </div>
                <div
                  class="btn btn-danger btn-xs ml-2 mt-4"
                  @click="closeUpdate()"
                  type="button"
                >
                  Close
                </div>
              </div>
              <div
                v-else
                class="btn btn-success btn-xs mt-4"
                @click="addItem"
                type="button"
              >
                Add Item
              </div>
            </div>
          </div>
        </div>
        <div class="bg-theam-secondary mt-2 purchase-ht3 purchase-table">
          <table class="table table1">
            <thead>
              <tr>
                <th class="wd-5p table-start-item" scope>S.N.</th>
                <th class="wd-35p" scope>Particular</th>
                <th class="wd-10p" scope>Qty</th>
                <th class="wd-5p" scope>Rate</th>
                <th class="wd-5p" scope>Disc.</th>
                <th class="wd-10p" scope>Total</th>
                <th class="wd-10p text-right table-end-item" scope>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in formData.items" :key="index">
                <th class="table-start-item" scope="row">{{ ++index }}</th>
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }} {{ item.unit_type }}</td>
                <td>{{ parseDigit(item.order_rate) }}</td>
                <td>{{ parseDigit(item.order_discount_amount) }}</td>
                <td>{{ parseDigit(item.total) }}</td>
                <td class="text-right table-end-item">
                  <a class="mr-2" href="javascript:" @click="editItem(index)">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a class="mr-0" href="javascript:" @click="removeItem(index)">
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 bg-theam-secondary p-2 mt-2">
        <div class="row">
          <div class="col-3 pr-0">
            <div class="page-text">Note</div>
            <textarea
              v-model.trim="formData.note"
              class="form-control page-note"
              name="note"
            ></textarea>
          </div>
          <div class="col-7">
            <div class="row">
              <div class="col-3 mr-0 pr-0">
                <div class="page-text">Grand Total</div>
                <div class="input-group">
                  <input
                    v-model="formData.total"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    step="any"
                    type="number"
                  />
                </div>
              </div>
              <div
                class="col-3 mr-0 pr-0"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">
                  VAT-able Amount
                  <i
                    class="fa fa-info-circle primary"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    title="
                      Please adjust vatable amount only after adding the extra fields.If you add/edit extra field after editing the vatable amount,vatable amount will be reset and will be recalculated.
                    "
                  ></i>
                </div>
                <div class="input-group">
                  <input
                    v-model="formData.vatable_amount"
                    class="form-control"
                    placeholder="0.00"
                    step="any"
                    type="number"
                    min="0"
                    @change="changeVatAbleAmount()"
                    @keyup="changeVatAbleAmount()"
                  />
                </div>
              </div>
              <div
                class="col-3 mr-0 pr-0"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">VAT Amount</div>
                <div class="input-group">
                  <input
                    v-model="formData.vat_amount"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-3 mr-0 pr-0">
                <div class="page-text">Advance Paid Amount *</div>
                <div class="input-group">
                  <input
                    v-model="formData.paid_amount"
                    class="form-control"
                    placeholder="0.00"
                    min="0"
                    step="any"
                    type="number"
                    @change="changePaidAmount()"
                    @keyup="changePaidAmount()"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div
                v-for="(extra_field, index) in extraFields"
                :key="index"
                class="col-2 mr-0 pr-0 mt-2"
              >
                <div
                  class="extra-cancel tx-danger"
                  title="Cancel"
                  @click="removeExtraField(index)"
                >
                  +
                </div>
                <div class="page-text">
                  {{ extra_field.title }} ({{
                    extra_field.type == 0 ? "+" : "-"
                  }})
                  <i
                    class="fa fa-info-circle primary"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    :title="extra_field.vat == 1 ? 'Vatable' : 'Non Vatable'"
                  ></i>
                </div>
                <div class="input-group">
                  <input
                    :id="`ext-${extra_field.slug}`"
                    :placeholder="extra_field.title"
                    class="form-control"
                    step="any"
                    type="number"
                    v-model="formData.reactiveExtraFields[index]"
                    @keyup="purchaseExtraFields({ ...extra_field })"
                    @change="purchaseExtraFields({ ...extra_field })"
                  />
                </div>
              </div>
              <!-- </div>
              </div>-->
              <div
                class="col-3 mr-0 pr-0 mt-2"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">Bill Amount</div>
                <div class="input-group">
                  <input
                    v-model="formData.bill_amount"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-3 mr-0 pr-0 mt-2">
                <div class="page-text">Due Amount</div>
                <div class="input-group">
                  <input
                    v-model="formData.due_amount"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-2 mr-0 pr-0 mt-3">
                <button
                  v-b-modal.modal-multi-2
                  class="btn btn-xs btn-primary"
                  @click.prevent
                >
                  Extra Field
                </button>
                <!-- second modal -->
                <b-modal
                  id="modal-multi-2"
                  size="sm"
                  centered
                  title="Add New Field"
                  ok-only
                  hide-footer
                >
                  <AddNewExtraFieldModal />
                </b-modal>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row justify-content-end mr-0">
              <button
                class="col-7 btn btn-success btn-sm btn-min-wd mb-2"
                type="submit"
                @click.prevent="submitData()"
                :disabled="submitting || loading"
              >
                <span v-if="loading">Loading...</span>
                <span v-else>{{
                  submitting ? "Submitting...." : "Submit"
                }}</span>
              </button>
              <!-- <button
                  class="col-7 btn btn-warning btn-sm btn-min-wd my-2"
                  type="button" @click="reset"
              >
                Reset
              </button>-->
              <router-link
                class="col-7 btn btn-danger btn-sm btn-min-wd"
                tag="button"
                to="/purchase/order"
                >Cancel</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="col-12">
      <div
        class="modal fade"
        id="supplierList"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog listing-popup-place modal_ac_head"
          role="document"
        >
          <div class="modal-content search-table-container">
            <!-- search field -->
            <div class="has-search">
              <span class="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                v-model="supplierSearched"
                @input="searchSupplier"
                ref="supplierSearch"
              />
            </div>
            <table id="tableData1" class="table table1">
              <thead>
                <tr>
                  <th class="wd-5p table-start-item" scope>S.N.</th>
                  <th class="wd-15p" scope>Supplier Id</th>
                  <th class="wd-30p" scope>Supplier Name</th>
                  <th class="wd-30p" scope>Address</th>
                  <th class="wd-20p" scope>Phone No</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(supplier, index) in this.dataSets.suppliers"
                  :key="index"
                  @click="selectSupplier(supplier.id)"
                  data-dismiss="modal"
                >
                  <th class="table-start-item" scope="row">{{ ++index }}</th>
                  <td>{{ supplier.id }}</td>
                  <td>{{ supplier.name }}</td>
                  <td>{{ supplier.address }}</td>
                  <td>{{ supplier.phone }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- shipping details Modal -->
    <div class="col-12">
      <div
        v-if="modalId == 'shippingDetils'"
        aria-hidden="true"
        class="modal fade modal_cust show"
        role="dialog"
        style="display: block"
        tabindex="-1"
      >
        <div
          class="
            modal-dialog modal-dialog-centered modal_ac_head
            single-column-popup
          "
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalCenterTitle" class="modal-title">
                Add Shipping Detial
              </h5>
              <a
                class="close"
                @click="$store.dispatch('modalClose', 'supplierList')"
                title="Close Modal"
              >
                <span aria-hidden="true">×</span>
              </a>
            </div>
            <div class="bg-theam-secondary">
              <div class="modal-body row">
                <div class="col-12 pd-r-7-5">
                  <div class="group">
                    <label class="group-label">Details</label>
                    <div class="group-attribute-container">
                      <div class="row mb-2">
                        <div class="col-5 mr-0 pr-0">Driver</div>
                        <div class="col-7 ml-0 pl-0">
                          <input
                            v-model="formData.driver"
                            class="form-control"
                            placeholder="Driver Name"
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-5 mr-0 pr-0">Vehicle</div>
                        <div class="col-7 ml-0 pl-0">
                          <input
                            v-model="formData.vehicle"
                            class="form-control"
                            placeholder="vehicle number"
                            type="input"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5 mr-0 pr-0">Contact</div>
                        <div class="col-7 ml-0 pl-0">
                          <input
                            v-model="formData.contact"
                            class="form-control"
                            placeholder="9840000000"
                            type="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-right mt-3">
                  <button
                    type="submit"
                    class="btn btn-sm btn-primary"
                    @click="$store.dispatch('modalClose', 'shippingDetils')"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewSupplier />
    <NewItem />

    <PurchaseItemComponent
      @clicked="componentItemClicked"
      ref="purchaseItemComponent"
    ></PurchaseItemComponent>
    <PurchaseOrderSlip @printSlip="printSlip" />
  </div>
</template>
<script>
/* eslint-disable */
import AddNewExtraFieldModal from "./Components/AddNewExtraFieldModal";
import NewSupplier from "../Supplier/NewSupplier";
import PurchaseOrderSlip from "./PurchaseOrderSlip";
import NewItem from "../Item/NewItem";
import PurchaseItemComponent from "./Components/PurchaseItemComponent";
import { mapGetters } from "vuex";
import Services from "./Services/Service";

export default {
  components: {
    NewSupplier,
    NewItem,
    AddNewExtraFieldModal,
    PurchaseItemComponent,
    PurchaseOrderSlip,
  },
  data() {
    return {
      options: {
        edit: false,
        index: null,
      },
      dataSets: {
        suppliers: [],
        items: [],
        paymentMethods: [],
        banks: [],
        branches: [],
        purchase_setting: [],
        item_setting: [],
      },
      loading: true,
      submitting: false,
      branchDisabled: false,
      supplierSearched: "",
      itemSearched: "",
      orderIdWithPrefix: 0,
      orderedId: 0,
      formData: {
        bill_no: "",
        prefix: "",
        paymentTitle: "",
        reactiveExtraFields: [],
        branch: "",
        branchName: "",
        supplier_id: null,
        supplierName: "",
        date: "",
        chalan_no: null,
        file: null,
        shipping_type: 0,
        driver: "",
        vehicle: "",
        contact: "",
        payment_method: "",
        extra_fields: [],
        advance_amount: "",
        bank: null,
        txn_id: null,
        purchase_amount: null,
        vatable_amount: null,
        vat_amount: null,
        bill_amount: null,
        paid_amount: null,
        due_amount: null,
        total: null,
        note: null,
        items: [],
      },
      items: {
        name: null,
        unit_type: null,
        vat_applicable: null,
        quantity: 0,
        order_rate: null,
        actual_purchase_rate: 0,
        sales_rate: null,
        sales_discount_percentage: null,
        sales_discount_amount: null,
        order_discount_percentage: null,
        order_discount_amount: null,
        total: 0,
        barcode: null,
        expiry_date: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "today",
      "dataId",
      "dataLists1",
      "dataLists8",
      "eventMessage",
      "modalId",
      "today",
      "authData",
    ]),
    ...mapGetters("purchase", [
      "extraFields",
      "branchId",
      "selectedExtra",
      "slipSettings",
    ]),
    ...mapGetters("suppliers", ["supplierDatas"]),
  },
  mounted() {
    this.$store.commit("purchase/removeExtraFields", "");
    this.getData();
  },
  methods: {
    searchSupplier: _.debounce(function () {
      let keyword = this.supplierSearched;
      // keyword = keyword ? keyword : '';
      Services.Purchase.getSuppliersBySearchKeyword(keyword)
        .then((response) => {
          let value = response.data.data.data;
          this.dataSets.suppliers = value;
        })
        .catch((error) => {
          console.log(error);
        });
    }, 600),

    // select the branch if the page relods with added Data
    checkIfBranchExist(branchId) {
      if (this.formData.items.length > 0) {
        if (this.formData.items[0].branch == branchId) {
          this.formData.branch = branchId;
          this.branchDisabled = true;
          return true;
        }
      }
      return false;
    },
    openPurchaseitemComponent() {
      if (this.formData.branch) {
        this.$store.dispatch("modalWithMode", {
          id: "purchaseItemList",
          mode: "create",
        });

        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.purchaseItemComponent.$refs.itemSearch.focus();
          });
        }, 600);
      } else {
        this.setNotification("Please select branch first.");
      }
    },
    // receiving data from child component i.e SalesItemComponent
    componentItemClicked(details) {
      this.items.item_id = details.id;
      this.items.name = details.name;
      this.items.unit_type = details.unit_type.title;
      this.items.vat_applicable = details.vat_applicable;
      this.items.quantity = 1;
      this.items.barcode = details.barcode;
      this.formData.date = this.today;
    },
    // focuses the input field when the item/customer modal is opened
    focusSearch(searchFieldId) {
      setTimeout(() => {
        this.$refs[searchFieldId].focus();
      }, 500);
    },
    clearItem() {
      this.items.vat_applicable = null;
      this.items.quantity = 0;
      this.items.name = null;
      this.items.order_rate = null;
      this.items.sales_rate = null;
      this.items.sales_discount_percentage = null;
      this.items.sales_discount_amount = null;
      this.items.total = 0;
      this.items.barcode = null;
      this.items.expiry_date = null;
      this.items.order_discount_percentage = null;
      this.items.order_discount_amount = null;
      this.items.actual_purchase_rate = 0;
      this.items.unit_type = null;
    },
    addItem() {
      if (this.itemValidation()) {
        if (this.formData.items.length > 0) {
          let id = this.items.item_id;
          let status = this.formData.items.find(function (data) {
            return data.item_id == id;
          });
          if (status != undefined) {
            let err = `${this.items.name} already in purchased list.`;
            this.setNotification(err);
            this.clearItem();
          } else {
            this.items.order_rate = parseFloat(this.items.order_rate || 0);

            this.items.order_discount_percentage = parseFloat(
              this.items.order_discount_percentage || 0
            );

            this.items.order_discount_amount = parseFloat(
              this.items.order_discount_amount || 0
            );

            this.items.sales_discount_percentage = parseFloat(
              this.items.sales_discount_percentage || 0
            );

            this.items.sales_discount_amount = parseFloat(
              this.items.sales_discount_amount || 0
            );
            this.formData.items.push({ ...this.items });
            // to disable the branch selection
            this.branchDisabled = true;
            this.calculateAmount();

            this.clearItem();
          }
        } else {
          this.items.order_rate = parseFloat(this.items.order_rate || 0);
          this.items.order_discount_percentage = parseFloat(
            this.items.order_discount_percentage || 0
          );

          this.items.order_discount_amount = parseFloat(
            this.items.order_discount_amount || 0
          );

          this.items.sales_discount_percentage = parseFloat(
            this.items.sales_discount_percentage || 0
          );

          this.items.sales_discount_amount = parseFloat(
            this.items.sales_discount_amount || 0
          );
          this.formData.items.push({ ...this.items });
          this.branchDisabled = true;
          this.addFormDataToLocalStorage(this.formData);
          this.calculateAmount();
          this.clearItem();
        }
      }
    },
    itemValidation() {
      if (!this.items.item_id) {
        this.setNotification("Please Select Item first.");
        return false;
      }
      if (this.items.quantity <= 0) {
        this.setNotification("Quantity must be greater than 0.");
        return false;
      }
      if (
        this.items.order_rate < 0 ||
        typeof this.items.order_rate == "undefined"
      ) {
        this.setNotification("Order Rate cannot be less than 0.");
        return false;
      }
      if (this.items.order_discount_percentage < 0) {
        this.setNotification(
          "Order discount percentage cannot be less than 0."
        );
        return false;
      }
      if (this.items.order_discount_amount < 0) {
        this.setNotification("Order discount amount cannot be less than 0.");
        return false;
      }

      if (this.items.total < 0 || typeof this.items.total == "undefined") {
        this.setNotification("Total cannot be less than 0.");
        return false;
      }

      return true;
    },
    // Notificaion
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },

    removeItem(index) {
      this.$swal({
        title: "Do you really want to remove this item ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.formData.items.splice(index - 1, 1);
          if (this.formData.items.length == 0) {
            this.branchDisabled = false;
          }
          this.calculateAmount();
          // this.addFormDataToLocalStorage(this.formData);
        }
      });
    },
    editItem(index) {
      this.items = { ...this.formData.items[index - 1] };
      this.options.edit = true;
      this.options.index = [index - 1];
    },
    updateItem() {
      if (this.itemValidation()) {
        let dataAdded = false;
        let currentAddedItems = this.formData.items;
        currentAddedItems.forEach((el, index) => {
          if (el.item_id == this.items.item_id) {
            dataAdded = true;
            this.formData.items[index] = { ...this.items };
          }
        });
        if (dataAdded == false) {
          this.formData.items.push({ ...this.items });
        }
        this.options.edit = false;
        this.branchDisabled = true;
        this.calculateAmount();
        this.clearItem();
      }
    },
    closeUpdate() {
      this.options.edit = false;
      this.items = [];
      this.itemExpired = false;
    },
    calculate() {
      this.items.total = (
        this.items.quantity * this.items.actual_purchase_rate
      ).toFixed(2);
    },

    calculatePurchaseDiscount() {
      let rate = this.items.order_rate;
      let itemQuantity = this.items.quantity;
      let p_discountPerc = this.items.order_discount_percentage;
      if (rate > 0 && p_discountPerc > 0) {
        let discAmount = parseFloat(
          (p_discountPerc * (itemQuantity * rate)) / 100
        );
        this.items.order_discount_amount = this.parseDigit(discAmount);
      } else if (rate > 0 && p_discountPerc == 0) {
        this.items.order_discount_amount = 0;
      }
      this.items.actual_purchase_rate = this.parseDigit(
        rate - (p_discountPerc * rate) / 100
      );
      this.calculate();
    },
    changePurchaseRate() {
      let rate = parseFloat(this.items.order_rate || 0);
      let purchaseDiscountAmt = parseFloat(this.items.order_discount_amount);

      if (purchaseDiscountAmt == 0 || isNaN(purchaseDiscountAmt)) {
        this.items.order_discount_percentage = 0;
        var purchaseDiscountPer = 0;
      } else {
        purchaseDiscountPer = this.parseDigit(
          (purchaseDiscountAmt / parseFloat(rate * this.items.quantity)) * 100
        );
        this.items.order_discount_percentage =
          this.parseDigit(purchaseDiscountPer);
      }
      this.items.actual_purchase_rate = this.parseDigit(
        rate - (purchaseDiscountPer * rate) / 100
      );
      this.calculate();
    },
    changeVatAbleAmount() {
      this.formData.vat_amount = this.parseDigit(
        parseFloat(this.formData.vatable_amount || 0) * 0.13
      );
      this.calculateCustomAmount();
    },
    calculateAmount() {
      this.formData.total = 0;
      this.formData.paid_amount =
        this.formData.paid_amount > 0
          ? parseFloat(this.formData.paid_amount || 0)
          : 0;
      let vat = 0;
      this.formData.items.forEach((item) => {
        this.formData.total = this.parseDigit(
          parseFloat(this.formData.total || 0) + parseFloat(item.total || 0)
        );
        if (item.vat_applicable == 1) {
          this.formData.vatable_amount = this.parseDigit(
            parseFloat(this.formData.vatable_amount || 0) +
              parseFloat(item.total || 0)
          );
        }
      });
      this.formData.vat_amount = this.parseDigit(
        parseFloat(this.formData.vatable_amount || 0) * 0.13
      );
      this.formData.bill_amount = this.parseDigit(
        parseFloat(this.formData.total || 0) +
          parseFloat(this.formData.vat_amount || 0)
      );
      //while editing the order , advance paid_amount = advance_amount
      this.formData.paid_amount = this.parseDigit(
        this.formData.advance_amount || 0
      );
      this.formData.due_amount = this.parseDigit(
        parseFloat(this.formData.bill_amount || 0) -
          parseFloat(this.formData.paid_amount || 0)
      );
      this.formData.due_amount =
        this.formData.due_amount > 0 ? this.formData.due_amount : 0;
      // this.calculateCustomAmount();
      this.calculateVatableAmtExtraFieldCase();
    },
    changePaidAmount() {
      if (this.formData.paid_amount > 0) {
        if (
          parseFloat(this.formData.paid_amount || 0) >
          this.parseDigit(this.formData.bill_amount)
        ) {
          this.formData.due_amount = 0;
        } else {
          this.formData.due_amount = this.parseDigit(
            parseFloat(this.formData.bill_amount || 0) -
              parseFloat(this.formData.paid_amount || 0)
          );
        }
      } else {
        this.formData.due_amount = this.parseDigit(this.formData.bill_amount);
      }
    },
    submitData() {
      let id = this.$route.params.id;
      if (this.formDataValidation()) {
        this.submitting = true;
        Services.PurchaseOrder.updatePurchaseOrderData(this.formData, id)
          .then((res) => {
            if (
              res.status == 200 &&
              res.data.success_message ==
                "Purchased order modified successfully."
            ) {
              this.reset();
              //making array because purchaseSlip always accepts an array of data
              let purchaseArray = [];
              purchaseArray.push(res.data.data.purchaseOrder);
              this.$store.commit("purchase/setDataSets", purchaseArray);

              // providing the organization and purchase setting to purchaseSlip component
              this.$store.commit("purchase/setSlipSettings", res.data.data);
              //poping up the purchaseSlip modal
              this.$store.commit("setDataId", res.data.data.purchaseOrder.id);

              this.$store.dispatch("modalWithMode", {
                id: "purchaseOrderSlip",
                mode: "read",
              });
              this.$store.commit("purchase/setSlipType", "purchaseOrderEdit");
            }
          })
          .catch((error) => {
            //validation error
            if (error.response.status == 422) {
              let err = Object.values(error.response.data.errors).flat();
              this.setNotification(err);
            } else {
              let err = error.response.data.error_message;
              this.setNotification(err);
            }
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    formDataValidation() {
      if (this.formData.items.length < 0) {
        this.setNotification("Please add items first.");
        return false;
      }
      if (this.formData.paid_amount < 0) {
        this.setNotification("Paid amount cannot be less than 0.");
        return false;
      }
      if (!this.formData.supplierName) {
        this.setNotification("Please select supplier first.");
        return false;
      }
      return true;
    },
    printSlip(id) {
      //once the modal is opened popup print window
      window.print();
    },
    selectSupplier(id) {
      let details = this.dataSets.suppliers.find(function (data) {
        return data.id == id;
      });
      this.formData.supplier_id = details.id;
      this.formData.supplierName = details.name;
    },
    selectPaymentMethod() {
      this.dataSets.paymentMethods.forEach((element) => {
        if (element.id == this.formData.payment_method) {
          if (element.title.toLowerCase() == "bank") {
            this.formData.paymentTitle = "bank";
            this.formData.bank =
              this.dataSets.banks.length > 0 ? this.dataSets.banks[0].id : "";
          } else {
            this.formData.bank = "";
            this.formData.paymentTitle = "";
          }
        }
      });
      //
      localStorage.setItem(
        "purchasePaymentMethod",
        this.formData.payment_method
      );
      // updating the localstorage fomrData;
      this.addFormDataToLocalStorage(this.formData);
    },
    selectBank() {
      this.addFormDataToLocalStorage(this.formData);
    },

    getData() {
      let id = this.$route.params.id;
      this.loading = true;
      Services.PurchaseOrder.getCheckoutData(id)
        .then((response) => {
          let value = response.data.data;
          this.dataSets.items = value.items;
          this.dataSets.suppliers = value.suppliers;
          this.dataSets.branches = value.branches;
          this.dataSets.purchase_setting = value.purchase_setting;
          this.dataSets.item_setting = value.item_setting;
          this.formData.items = value.purchase_order.items;
          this.formData.note = value.purchase_order.note;
          this.formData.prefix = this.dataSets.purchase_setting.prefix;
          this.formData.supplier_id = value.purchase_order.supplier.id;
          this.formData.supplierName = value.purchase_order.supplier.name;
          this.orderIdWithPrefix = `${this.formData.prefix}${value.purchase_order_no}`;
          this.formData.bill_no = value.purchase_order.purchase_order_no;
          this.formData.advance_amount = value.purchase_order.paid_amount;
          this.formData.vatable_amount = value.purchase_order.vatable_amount;
          this.formData.vat_amount = value.purchase_order.vat_amount;
          this.formData.total = value.purchase_order.total;
          this.formData.paid_amount = value.purchase_order.paid_amount;
          this.formData.bill_amount = value.purchase_order.bill_amount;
          this.formData.due_amount = value.purchase_order.due_amount;

          //Making Api call for branch details
          this.formData.branch = value.purchase_order.branch;
          this.branchDisabled = true;
          Services.PurchaseOrder.getPurchaseItemsByBranch(this.formData.branch)
            .then((response) => {
              this.loading = false;
              let bvalue = response.data.data;
              this.$store.commit("purchase/setBranchId", this.formData.branch);
              this.dataSets.paymentMethods = bvalue.paymentMethods;
              this.dataSets.banks = bvalue.banks;
              this.orderedId = bvalue.orderedId;
            })
            .catch((error) => {
              if (typeof error.response.data.error_message != "undefiend ") {
                if (error.response.data.error_message == "Access Denied.") {
                  this.$router.push({ name: "access-denied" });
                }
              }
            });

          this.orderIdWithPrefix = `${this.dataSets.purchase_setting.prefix}${value.purchase_order.purchase_order_no}`;
          this.formData.date = value.purchase_order.date;
          this.formData.contact = value.purchase_order.contact;
          this.formData.driver = value.purchase_order.driver;
          this.formData.vehicle = value.purchase_order.vehicle;
          this.formData.shipping_type = value.purchase_order.shipping_type;
          this.formData.payment_method = value.purchase_order.payment_method;
          this.formData.bank = value.purchase_order.bank;
          if (this.formData.bank) {
            this.formData.paymentTitle = "bank";
          }
          this.formData.txn_id = value.purchase_order.txn_id;

          this.$store.commit("purchase/setItemToBePurchased", value.items);
          this.$store.commit("purchase/setItemGroups", value.item_groups);
          if (value.extra_fields.length > 0) {
            this.$store.commit(
              "purchase/setExtraFieldsFromPurchaseOrder",
              value.extra_fields
            );
            value.extra_fields.forEach((el) => {
              this.formData.reactiveExtraFields.push(el.amount);
            });
          }
          // this.calculateAmount();
        })
        .catch((error) => {
          if (typeof error.response.data.error_message != "undefiend ") {
            if (error.response.data.error_message == "Access Denied.") {
              this.$router.push({ name: "access-denied" });
            }
          }
        });
    },
    getDataByBranch() {
      if (this.formData.branch) {
        this.clearItem();
        this.formData.payment_method = "";
        this.formData.paymentTitle = "";
        this.formData.bank = "";
        this.itemExpired = false;
        localStorage.removeItem("salesOrderPaymentMethod");
        this.getBrachDetails();
      } else {
        this.orderIdWithPrefix = 1;
      }
    },
    getBrachDetails() {
      Services.PurchaseOrder.getPurchaseItemsByBranch(this.formData.branch)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("purchase/setBranchId", this.formData.branch);
          this.dataSets.paymentMethods = value.paymentMethods;
          this.dataSets.banks = value.banks;
          this.orderedId = value.orderedId;
          this.formData.bill_no = value.orderedId;
          this.orderIdWithPrefix = `${this.dataSets.purchase_setting.prefix}${value.orderedId}`;
          this.dataSets.paymentMethods.forEach((payment) => {
            if (payment.title.toLowerCase() == "cash") {
              this.formData.payment_method = payment.id;
            }
          });
        })
        .catch((error) => {
          if (typeof error.response.data.error_message != "undefiend ") {
            if (error.response.data.error_message == "Access Denied.") {
              this.$router.push({ name: "access-denied" });
            }
          }
        });
    },
    setFormData() {
      this.formData = this.dataLists8;
      if (this.dataLists8.shipping_details) {
        this.formData.driver = this.dataLists8.shipping_details.driver;
        this.formData.vehicle = this.dataLists8.shipping_details.vehicle;
        this.formData.contact = this.dataLists8.shipping_details.contact;
      }
      for (let i = 0; i < this.dataLists8.items.length; i++) {
        this.formData.items[i].name = this.dataLists8.items[i].item.name;
        this.formData.items[i].vat_applicable =
          this.dataLists8.items[i].item.vat_applicable;
        this.formData.items[i].unit_type =
          this.dataLists8.items[i].item.unit_type.title;
      }
    },
    purchaseExtraFields(details) {
      let index = this.formData.extra_fields.findIndex(
        (x) => x.slug === details.slug
      );
      if (index >= 0) {
        let amount = document.getElementById(`ext-${details.slug}`).value;
        if (amount) {
          this.formData.extra_fields[index].amount = amount;
        } else {
          this.formData.extra_fields[index].amount = 0;
        }
      } else {
        let amount = document.getElementById(`ext-${details.slug}`).value;
        if (amount) {
          this.formData.extra_fields.push({
            title: details.title,
            slug: details.slug,
            type: details.type,
            vat: details.vat,
            amount: amount,
          });
        }
      }
      this.calculateVatableAmtExtraFieldCase();
      // adding data to localStorage
      // this.addFormDataToLocalStorage(this.formData);
    },
    calculateVatableAmtExtraFieldCase() {
      this.formData.extra_fields.forEach((extra, index) => {
        if (index == 0) {
          this.newVatable = 0;
        }
        if (parseInt(extra.vat) == 1 && parseInt(extra.type) == 1) {
          this.newVatable = this.newVatable - parseFloat(extra.amount || 0);
        } else if (parseInt(extra.vat) == 1 && parseInt(extra.type) == 0) {
          this.newVatable = this.newVatable + parseFloat(extra.amount || 0);
        } else {
          this.newVatable = this.newVatable + 0;
        }
      });

      this.calculateTotal();
    },

    calculateTotal() {
      this.formData.vatable_amount = 0;
      this.formData.vat_amount = 0;
      this.formData.bill_amount = 0;
      this.formData.due_amount = 0;
      this.formData.total = 0;
      this.formData.paid_amount =
        this.formData.paid_amount > 0
          ? this.parseDigit(this.formData.paid_amount)
          : 0;
      var itemVatable = false;
      this.formData.items.forEach((item) => {
        this.formData.total = this.parseDigit(
          this.formData.total + parseFloat(item.total)
        );
        if (this.formData.shipping_type == 0 && item.vat_applicable == 1) {
          itemVatable = true;
          this.formData.vatable_amount = this.parseDigit(
            parseFloat(this.formData.vatable_amount || 0) +
              parseFloat(item.total)
          );
        }
      });
      if (itemVatable) {
        this.formData.vatable_amount = this.parseDigit(
          parseFloat(this.formData.vatable_amount || 0) +
            parseFloat(this.newVatable || 0)
        );
      }

      this.calculateCustomAmount();
    },

    calculateCustomAmount() {
      this.formData.vat_amount = this.parseDigit(
        parseFloat(this.formData.vatable_amount || 0) * 0.13
      ).toFixed(2);
      this.formData.purchase_amount = this.parseDigit(this.formData.total);
      this.formData.bill_amount = this.parseDigit(
        parseFloat(this.formData.total) +
          parseFloat(this.formData.vat_amount || 0)
      );
      this.formData.extra_fields.forEach((extra) => {
        if (parseInt(extra.type) == 0) {
          this.formData.bill_amount = this.parseDigit(
            parseFloat(this.formData.bill_amount || 0) +
              parseFloat(extra.amount || 0)
          );
          this.formData.purchase_amount = this.parseDigit(
            parseFloat(this.formData.purchase_amount || 0) +
              parseFloat(extra.amount || 0)
          );
        } else {
          this.formData.bill_amount = this.parseDigit(
            parseFloat(this.formData.bill_amount || 0) -
              parseFloat(extra.amount || 0)
          );
          this.formData.purchase_amount = this.parseDigit(
            parseFloat(this.formData.purchase_amount || 0) -
              parseFloat(extra.amount || 0)
          );
        }
      });
      this.formData.paid_amount = this.parseDigit(this.formData.advance_amount);
      this.calculateDue();
    },

    calculateDue() {
      // if (this.formData.paid_amount > this.formData.bill_amount) {
      //   this.formData.paid_amount = this.formData.bill_amount;
      // }
      this.formData.due_amount = this.parseDigit(
        this.parseDigit(this.formData.bill_amount) -
          parseFloat(this.formData.paid_amount || 0)
      );
      this.formData.due_amount =
        this.formData.due_amount > 0
          ? parseFloat(this.formData.due_amount || 0)
          : 0;
    },
    reset() {
      this.formData.chalan_no = null;
      this.formData.shipping_type = 0;
      this.formData.supplierName = "";
      (this.formData.supplier_id = null), (this.formData.driver = "");
      this.formData.vehicle = "";
      this.formData.contact = "";
      this.formData.payment_method = "";
      this.formData.paymentTitle = "";
      this.formData.reactiveExtraFields = [];
      this.formData.bank = null;
      this.formData.txn_id = null;
      this.formData.vatable_amount = null;
      this.formData.vat_amount = null;
      this.formData.bill_amount = null;
      this.formData.extra_fields = [];
      this.formData.paid_amount = null;
      this.formData.due_amount = null;
      this.formData.total = null;
      this.formData.items = [];
      // single and multiple branch case
      if (this.dataSets.branches.length == 1) {
        this.branchDisabled = true;
        this.orderedId++;
        this.formData.bill_no = this.orderedId;
        this.orderIdWithPrefix = this.formData.prefix + this.formData.bill_no;
      } else {
        this.branchDisabled = false;
        this.formData.branch = "";
        this.formData.bill_no = 0;
        this.dataSets.paymentMethods = [];
      }
      this.$store.commit("purchase/removeExtraFields", "");
      this.clearItem();
    },
    addFormDataToLocalStorage(value) {
      // localStorage.setItem("purchaseOrderFormData", JSON.stringify(value));
      this.$store.commit("purchase/setBranchId", this.formData.branch);
    },
    removeExtraField(index) {
      this.$swal({
        title: "Do you really want to remove this extra field ?",
        showCancelButton: true,
        confirmButtonText: "Yes, Remove it!",
        cancelButtonText: "No",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.extraFields.forEach((ex, i) => {
            if (i == index) {
              this.extraFields.splice(i, 1);
              this.formData.reactiveExtraFields.forEach((re, j) => {
                if (j == index) {
                  this.formData.reactiveExtraFields.splice(j, 1);
                }
                this.calculateVatableAmtExtraFieldCase();
              });
            }
          });
        }
      });
    },
  },
  watch: {
    dataLists8(value) {
      if (value.length != 0) {
        this.setFormData();
      }
    },
    extraFields(value) {
      this.formData.extra_fields = value;
    },
    eventMessage(value) {
      // if (value.indexOf("created success") >= 0) {
      //   this.getData();
      // } else if (value.indexOf("Purchased successfully") >= 0) {
      //   this.$router.push("/purchase/manage");
      // }
      if (value.indexOf("Supplier created successfully.") >= 0) {
        this.dataSets.suppliers = this.supplierDatas;
      }
    },
  },

  destroyed() {
    this.$store.dispatch("destroyEvent");
    localStorage.removeItem("items");
  },
};
</script>
<style scoped>
.form-control-custom h6 {
  font-weight: 600;
}

.right-select-drop {
  background: #1b52bf !important;
  color: #f4f7fa !important;
  border-radius: 5px !important;
  height: 1.4rem !important;
  font-size: 0.75rem;
}

.input-group-text {
  background: #1b52bf;
  color: #f4f7fa;
  z-index: 20;
}

.input-group-text {
  outline: none;
}

input,
select,
textarea {
  background: #f4f7fa;
}

input:focus,
select:focus,
textarea:focus {
  background: #ffffff;
}

.form-control:disabled {
  background: #9ac3f0 !important;
  color: black !important;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  top: 4px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
</style>
